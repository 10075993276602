import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { ThunkApiType } from 'types/utils';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const logoutThunk = async (
	_: { instance: IPublicClientApplication; accounts: AccountInfo[] },
	thunkAPI: ThunkApiType,
) => {
	try {
		const response = await authFetch.post('/logout', {});
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
