import { Box as BoxMui, BoxProps as BoxPropsMui } from '@mui/material';
import { forwardRef } from 'react';

import styled from 'styled-components';

type BoxProps = BoxPropsMui;

const StyledBox = styled(BoxMui)<BoxProps>`
	&::-webkit-scrollbar {
		height: 8px;
		width: 8px;
		border-radius: 6px;
		border: none;
	}
	&::-webkit-scrollbar-track {
		background-color: white;
		border-radius: 6px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 6px;
		height: 8px;
		width: 8px;
		background-color: #031434;
	}
`;

const Box: any = forwardRef((props: any, ref: any) => {
	return (
		<StyledBox {...props} ref={ref}>
			{props.children}
		</StyledBox>
	);
});

export default Box;
