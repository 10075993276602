import { createClaimsSearchValues, getShortDate } from 'utils/utils';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { IClaimDownload } from 'types/Claims';
import { ThunkApiType } from 'types/utils';
import { exportListToExcel } from 'utils/download';

export const getClaimsByProviderThunk = async (
	_: string,
	thunkAPI: ThunkApiType,
) => {
	let getClaimsURL = `/claim`;

	const { page } = thunkAPI.getState().claims;
	const claimsSearchValues = thunkAPI.getState().form;

	const { sortingFields, sortingDirections } = thunkAPI.getState().form;

	let sortField = '';
	let sortDirection = '';

	if (sortingFields === '') {
		sortField = 'serviceDate,externalClaimId';
		sortDirection = 'DESC,DESC';
	} else {
		sortField = sortingFields;
		sortDirection = sortingDirections;
	}
	const searchParams = createClaimsSearchValues(claimsSearchValues);
	getClaimsURL =
		getClaimsURL +
		`?page=${page}&sortingFields=${sortField}&sortingDirections=${sortDirection}${
			searchParams ? searchParams : ''
		}`;
	try {
		const response = await authFetch.get(getClaimsURL);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getClaimsStatusThunk = async (
	_: string,
	thunkAPI: ThunkApiType,
) => {
	let getClaimsStatusURL = '/claim/status';

	try {
		const response = await authFetch.get(getClaimsStatusURL);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const downloadClaimsThunk = async (
	{ id, type }: IClaimDownload,
	thunkAPI: ThunkApiType,
) => {
	let getDownloadClaimIdURL = '/claim/downloadClaimDetail';
	let getDownloadClaimsListURL = `/claim/downloadClaimList`;

	if (id !== 0) {
		getDownloadClaimIdURL = getDownloadClaimIdURL + `?claimId=${id}`;
		try {
			const response = await authFetch.get(getDownloadClaimIdURL, {
				responseType: 'blob',
			});
			exportListToExcel(response.data, 'Claim', type);
			return true;
		} catch (error: ThunkApiType) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	} else {
		const { claimIds, claimExcludeIds, downloadAll } = thunkAPI.getState().claims;
		const {
			inputExternalClaimId,
			inputFirstName,
			inputLastName,
			dateOfService,
			inputClaimStatus,
			sortingFields,
			sortingDirections,
		} = thunkAPI.getState().form;

		let sortField = '';
		let sortDirection = '';
		if (sortingFields === '') {
			sortField = 'serviceDate,externalClaimId';
			sortDirection = 'DESC,DESC';
		} else {
			sortField = sortingFields;
			sortDirection = sortingDirections;
		}

		const values = {
			format: type,
			claimIds: downloadAll ? [] : claimIds,
			claimExcludeIds: claimExcludeIds,
			firstName: inputFirstName ? inputFirstName : null,
			lastName: inputLastName ? inputLastName : null,
			serviceDate: dateOfService ? getShortDate(dateOfService) : null,
			claimStatus: inputClaimStatus ? inputClaimStatus : null,
			externalClaimId: inputExternalClaimId ? inputExternalClaimId : null,
			sortingFields: sortField,
			sortingDirections: sortDirection,
		};
		try {
			const response = await authFetch.post(getDownloadClaimsListURL, values, {
				responseType: 'blob',
			});
			exportListToExcel(response.data, 'Claims', type);
			return true;
		} catch (error: ThunkApiType) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
};
