import {
	resetDialogConfirm,
	setDialogOpen,
	setInjectionState,
} from 'feature/hooks/dialogSlice';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

import { VERSION } from 'lodash';
import {
	CHECK_CONNECTION_ERROR,
	GENERIC_ERROR,
	NO_PROVIDER,
	TOKEN_EXPIRED,
} from 'types/dialogTypes';
import { useLogout } from './login.hook';

const useDialogConfirm = () => {
	const dispatch = useDispatch<AppDispatch>();

	const { type } = useSelector((store: RootState) => store.hooks.dialog);

	const mapping = useCallback((type: string, text: string) => {
		switch (type) {
			case GENERIC_ERROR:
				return {
					title: 'dialog.confirm.error.title',
					content: text || 'dialog.confirm.error.content',
					labelCancel: 'dialog.buttons.cancel',
					variantButtonCancel: 'contained',
					type: type,
					buttonClose: true,
				};
			case VERSION:
				return {
					title: 'dialog.version.title',
					content: 'dialog.version.contentTitle',
					type: 'versionModal',
					buttonClose: true,
				};
			default:
		}
	}, []);

	const handleLogout = useLogout();

	const closeDialog = useCallback(() => {
		dispatch(setDialogOpen(false));
		dispatch(resetDialogConfirm());

		switch (type) {
			case TOKEN_EXPIRED:
				handleLogout();
				break;
			default:
				break;
		}
	}, [dispatch, handleLogout, type]);

	const handleOpen = useCallback(
		(type: string, text?: string) => {
			dispatch(setInjectionState(mapping(type, text || '')));
			dispatch(setDialogOpen(true));
		},
		[dispatch, mapping],
	);

	const handleConfirm = useCallback(() => {
		dispatch(resetDialogConfirm());
		switch (type) {
			case NO_PROVIDER:
			case CHECK_CONNECTION_ERROR:
			case TOKEN_EXPIRED:
				handleLogout();
				break;
			default:
				break;
		}
		dispatch(setDialogOpen(false));
	}, [dispatch, handleLogout, type]);

	return useMemo(
		() => ({
			open: handleOpen,
			close: closeDialog,
			confirm: handleConfirm,
		}),
		[closeDialog, handleConfirm, handleOpen],
	);
};

export default useDialogConfirm;
