import {
	Tooltip as TooltipMui,
	TooltipProps as TooltipPropsMui,
} from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

type TooltipProps = TooltipPropsMui;

const StyledTooltip = styled(TooltipMui)<TooltipProps>({});

const Tooltip = React.forwardRef<HTMLElement, TooltipProps>((props, ref) => {
	return (
		<StyledTooltip {...props} ref={ref}>
			{props.children}
		</StyledTooltip>
	);
});

export default Tooltip;
