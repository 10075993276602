import { CompositeTypography } from 'styles';
import Paper from 'styles/paper/paper';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { useTranslate } from 'stylesHooks';
import { IClaim } from 'types/Claim';
import { TRANSLATION_KEYS } from 'utils/constants';

const ClaimDetailsReasonCode = ({ claim }: { claim: IClaim }) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	return (
		<Paper
			elevation={0}
			sx={{
				p: 2.5,
				justifyContent: 'space-between',
				display: 'flex',
				borderRadius: 0.5,
				width: '100%',
			}}
		>
			<Stack flexGrow={1} rowGap={1.875}>
				<Typography variant="body">
					<CompositeTypography
						label={tClientLabels('claim.labels.reasonCode')}
						value={claim?.reasonCode as string}
					/>
				</Typography>
				<Typography variant="body">
					<CompositeTypography
						label={tClientLabels('claim.labels.internalNote')}
						value={claim?.internalNote ? (claim.internalNote as string) : '-'}
					/>
				</Typography>
			</Stack>
		</Paper>
	);
};

export default ClaimDetailsReasonCode;
