import 'dayjs/locale/en-gb';

import { useCheckAlive, useGetLocales, useLogin } from 'hooks/login.hook';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ViewClaimDetails, ViewClaims } from '../pages';

import { AuthenticatedTemplate } from '@azure/msal-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTolgee } from '@tolgee/react';
import SharedLayout from 'components/Layout//SharedLayout/SharedLayout.component';
import DialogConfirm from 'components/commons/Dialogs/DialogConfirm';
import dayjs from 'dayjs';

interface Props {
	className?: string;
}

export const Router: React.FC<Props> = () => {
	const tolgee = useTolgee(['language']);
	const [dateJsStarted, setDatejsStarted] = useState(false);

	//login
	useLogin();

	// check connection
	useCheckAlive();

	// check connection
	useGetLocales();

	useEffect(() => {
		dayjs.locale(tolgee.getLanguage());
		setDatejsStarted(true);
	}, [tolgee]);

	return (
		<AuthenticatedTemplate>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
				<DialogConfirm />
				{!dateJsStarted ? null : (
					<Routes>
						<Route path="/view-claims" element={<SharedLayout />}>
							<Route path="/view-claims" element={<ViewClaims />} />
							<Route path="/view-claims/:id" element={<ViewClaimDetails />} />
						</Route>
						<Route path="*" element={<Navigate to="/view-claims" />} />
					</Routes>
				)}
			</LocalizationProvider>
		</AuthenticatedTemplate>
	);
};
