import { combineReducers, configureStore } from '@reduxjs/toolkit';

import dialogReducer from 'feature/hooks/dialogSlice';
import locationHookSlice from 'feature/hooks/locationSlice';
import progressIndicatorReducer from 'feature/progressIndicator/progressIndicatorSlice';
import claimReducer from '../feature/claims/claimSlice';
import claimsReducer from '../feature/claims/claimsSlice';
import formReducer from '../feature/form/formSlice';
import inputReducer from '../feature/input/inputSlice';
import searchReducer from '../feature/search/searchSlice';
import userReducer from '../feature/user/userSlice';
import healthCheckReducer from '../feature/utils/checkConnectionSlice';

const rootReducer = {
	user: userReducer,
	progressIndicator: progressIndicatorReducer,
	search: searchReducer,
	hooks: combineReducers({
		dialog: dialogReducer,
		location: locationHookSlice,
	}),
	claims: claimsReducer,
	claim: claimReducer,
	form: formReducer,
	input: inputReducer,
	healthCheck: healthCheckReducer,
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
